import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/Bio"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Social from "../components/Social"

function BlogIndex({
  data: {
    allMarkdownRemark: { edges: posts },
    site: {
      siteMetadata: { author, title: siteTitle },
    },
  },
  location,
}) {
  return (
    <Layout author={author} location={location} title={siteTitle}>
      <Bio location={location} />
      <h2>⌨️ Writing</h2>
      {posts
        .filter(({ node: { frontmatter } }) => !frontmatter.hidden)
        .map(({ node: { fields, frontmatter } }) => {
          const { external: externalLink, file, publication } = frontmatter
          const title = frontmatter.title || fields.slug

          return (
            <article className="post-list-item" key={fields.slug}>
              <header>
                <h3>
                  {externalLink || file ? (
                    <a href={externalLink || file.publicURL}>{title}</a>
                  ) : (
                    <Link to={fields.slug}>{title}</Link>
                  )}
                </h3>
                <small>
                  {frontmatter.date}
                  {publication ? (
                    <>
                      {` • Published on `}
                      <a className="publication-link" href={publication.url}>
                        {publication.name}
                      </a>
                    </>
                  ) : null}
                </small>
              </header>
            </article>
          )
        })}
      <Social />
    </Layout>
  )
}

export default BlogIndex

export const Head = ({ location }) => <Seo location={location} title="Home" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        author
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            description
            external
            file {
              publicURL
            }
            hidden
            publication {
              name
              url
            }
            title
          }
        }
      }
    }
  }
`
