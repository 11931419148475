import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Social = () => {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      site {
        siteMetadata {
          social {
            carousell
            github
            linkedin
          }
        }
      }
    }
  `)

  const { social } = data.site.siteMetadata
  return (
    <div className="social-links">
      <p>
        <a href={`https://www.carousell.sg/u/${social.carousell}/`}>
          carousell
        </a>
        {" • "}
        <a href={`https://github.com/${social.github}`}>github</a>
        {" • "}
        <a href={`https://www.linkedin.com/in/${social.linkedin}/`}>linkedin</a>
        {" • "}
        <a href={`/rss.xml`}>rss</a>
      </p>
    </div>
  )
}

export default Social
